<template>
    <div>
        <van-sticky>
            <title-bar2 title="查资料" />
            <title-search :value.sync="keyword" placeholder="请输入搜索资料" @search="onSearch" />
        </van-sticky>
        <div class="document-divider"></div>
        <div class="guide-box">
            <span class="num-box">
                <span class="num1">1</span>
                <span class="num2">/</span>
                <span class="num2">2</span>
            </span>
            <span class="txt">请输入查询信息</span>
        </div>
        <div class="document-divider"></div>
        <div class="card-box">
            <document-filter :filterActive.sync="filterActive" :filterData1.sync="filterData1" :filterData2.sync="filterData2" :filterData3.sync="filterData3" @filter="onFilter"></document-filter>
        </div>
        <div v-if="majorInfo && filterActive == 0" class="document-major">
            <div class="title">研究方向</div>
            <div class="text">{{ majorInfo.MajorResearchDirection }}</div>
            <div class="title">初试科目</div>
            <span class="text">{{ majorInfo.MajorFirstSubject }}</span>
            <div class="title">复试科目</div>
            <span class="text">{{ majorInfo.MajorSecondSubject }}</span>
        </div>
        <div class="document-divider"></div>
        <div class="guide-box">
            <span class="num-box">
                <span class="num1">2</span>
                <span class="num2">/</span>
                <span class="num2">2</span>
            </span>
            <span class="txt">查询结果（点击可预览）</span>
        </div>
        <van-tabs v-model="typeActive" @change="onChange">
            <van-tab name=" " :title="`全部 ${countAll}`"></van-tab>
            <van-tab name="2" :title="`真题 ${countExamSubject}`"></van-tab>
            <van-tab name="0" :title="`初试 ${countFirstSubject}`"></van-tab>
            <van-tab name="1" :title="`复试 ${countSecondSubject}`" v-if="filterActive != 1"></van-tab>
        </van-tabs>
        <van-list class="document-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <template v-for="documentInfo in documentInfos">
                <document-item class="document-item" :documentName="documentInfo.DocumentName" :documentPrice="documentInfo.DocumentPrice" @item-click="onItemClick(documentInfo)"></document-item>
            </template>
        </van-list>
    </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import TitleSearch from "@/components/MT/TitleSearch";
import DocumentFilter from "@/components/MT/DocumentFilter";
import DocumentItem from "@/components/MT/DocumentItem";
import Config from "@/api/service";

export default {
    name: "MTModule",
    components: {
        TitleBar2,
        TitleSearch,
        DocumentFilter,
        DocumentItem,
    },
    data() {
        return {
            keyword: null,
            index: 0,
            total: 0,
            loading: false,
            finished: false,
            countAll: 0,
            countExamSubject: 0,
            countFirstSubject: 0,
            countSecondSubject: 0,
            keyword: '',
            typeActive: ' ',
            filterActive: 0,
            filterData1: {
                universityCode: '',
                collegeCode: '',
                majorCode: '',
            },
            filterData2: {
                universityCode: '',
                examSubjectCode: '',
                examSubjectName: '',
            },
            filterData3: {
                universityCode: '',
                majorCode: '',
                examSubjectName: '',
            },
            majorInfo: null,
            documentInfos: [],
        };
    },
    methods: {
        //获取路由参数
        getRoutParams() {
            this.keyword = this.$route.query.keyword;
        },
        //获取专业信息
        getMajorInfo() {
            if (this.filterActive == 0 && this.filterData1.universityCode && this.filterData1.collegeCode && this.filterData1.majorCode) {
                var model = {
                    UniversityCode: this.filterData1.universityCode,
                    CollegeCode: this.filterData1.collegeCode,
                    MajorCode: this.filterData1.majorCode,
                };
                this.$axios
                    .post(Config.document.major.info, model)
                    .then((res) => {
                        this.majorInfo = res.data.Data;
                    })
                    .catch((error) => {
                        this.$dialog({ title: "错误", message: error.message });
                    });
            }
        },
        //获取文档数量
        getDocumentCount() {
            if (this.filterActive == 0) {
                var model = {
                    SearchType: 0,
                    SearchKeyword: this.keyword,
                    UniversityCode: this.filterData1.universityCode,
                    MajorCode: this.filterData1.majorCode,
                    ExamSubjectList: this.filterData1.examSubjectList,
                };
            } else if (this.filterActive == 1) {
                var model = {
                    SearchType: 1,
                    SearchKeyword: this.keyword,
                    UniversityCode: this.filterData2.universityCode,
                    ExamSubjectCode: this.filterData2.examSubjectCode,
                    ExamSubjectName: this.filterData2.examSubjectName,
                };
            } else if (this.filterActive == 2) {
                var model = {
                    SearchType: 0,
                    SearchKeyword: this.keyword,
                    UniversityCode: this.filterData3.universityCode,
                    MajorCode: this.filterData3.majorCode,
                    ExamSubjectList: this.filterData3.firstSubjectList,
                };
            }
            this.$axios
                .post(Config.document.count, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.countAll = data.CountAll;
                    this.countFirstSubject = data.CountFirstSubject;
                    this.countSecondSubject = data.CountSecondSubject;
                    this.countExamSubject = data.CountExamSubject;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取文档列表
        getDocumentInfos() {
            this.loading = true;
            if (this.filterActive == 0) {
                var model = {
                    SearchType: 0,
                    SearchKeyword: this.keyword,
                    DocumentType: this.typeActive,
                    UniversityCode: this.filterData1.universityCode,
                    MajorCode: this.filterData1.majorCode,
                    ExamSubjectList: this.filterData1.examSubjectList,
                    PageIndex: ++this.index,
                    PageSize: 20,
                };
            } else if (this.filterActive == 1) {
                var model = {
                    SearchType: 1,
                    SearchKeyword: this.keyword,
                    DocumentType: this.typeActive,
                    UniversityCode: this.filterData2.universityCode,
                    ExamSubjectCode: this.filterData2.examSubjectCode,
                    ExamSubjectName: this.filterData2.examSubjectName,
                    PageIndex: ++this.index,
                    PageSize: 20,
                };
            } else if (this.filterActive == 2) {
                var model = {
                    SearchType: 0,
                    SearchKeyword: this.keyword,
                    DocumentType: this.typeActive,
                    UniversityCode: this.filterData3.universityCode,
                    MajorCode: this.filterData3.majorCode,
                    ExamSubjectList: this.filterData3.firstSubjectList,
                    PageIndex: ++this.index,
                    PageSize: 20,
                };
            }
            this.$axios
                .post(Config.document.list, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.documentInfos.push(...data.DocumentInfos);
                    this.loading = false;
                    this.total = data.TotalCount;
                    if (this.index >= data.PageCount) {
                        this.finished = true;
                    }
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                }).finally(() => {
                    this.loading = false;
                });
        },
        //点击资料项
        onItemClick(documentInfo) {
            this.$router.push({
                name: 'Document1',
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    documentGuid: documentInfo.DocumentGuid
                }
            })
        },
        //点击搜索
        onSearch() {
            for (var key in this.filterData1) {
                this.filterData1[key] = null;
            }
            for (var key in this.filterData2) {
                this.filterData2[key] = null;
            }
            for (var key in this.filterData3) {
                this.filterData3[key] = null;
            }
            this.onReload();
        },
        //点击过滤
        onFilter() {
            this.keyword = '';
            this.onReload();
        },
        //重新加载列表
        onReload() {
            this.finished = false;
            this.loading = true;
            this.index = 0;
            this.documentInfos = [];
            this.getDocumentCount();
            this.getDocumentInfos();
            this.getMajorInfo();
        },
        //加载列表
        onLoad() {
            this.getDocumentInfos();
        },
        //选项卡切换
        onChange() {
            this.onReload();
        },
    },
    mounted() {
        this.getRoutParams();
        this.getDocumentCount();
        this.getDocumentInfos();
        this.getMajorInfo();
    }
};
</script>

<style scoped lang="less">
.document-list {
    margin-top: 10px;
}

.document-item {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.document-divider {
    margin: 20px 0;
}

.document-major {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;

    .title {
        font-size: 14px;
        font-weight: 500;
        margin: 5px 0;
    }

    .text {
        line-height: 26px;
        color: gray;
        font-size: 14px;
        margin-bottom: 10px;
        white-space: pre-wrap;
    }
}


.guide-box {
    background-color: #F6F6F6;
    padding-left: 10px;
    border-width: 0px;
    font-size: 14px;
    font-weight: 600;

    .num-box {
        display: inline;
        letter-spacing: 5px;

        .num1 {
            color: #FF6602;
            font-size: 18px;
            font-weight: 700;
        }

        .num2 {
            color: gray;
            font-size: 14px;
        }
    }
}

.card-box {
    background-color: white;
    margin: 10px;
    padding: 5px;
    border-radius: 10px;
}

::v-deep .van-tabs__nav {
    background-color: transparent;
}

::v-deep .el-table__fixed {
    z-index: 0;
}

::v-deep .el-table td.el-table__cell div {
    white-space: pre-wrap;
}
</style>