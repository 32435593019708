<template>
  <div>
    <van-tabs v-model="activeValue">
      <van-tab title="按照专业">
        <van-cell-group>
          <van-field is-link readonly label="学校" placeholder="请选择学校" :value="universityText1" :rules="[{ required: true }]" @click="onPopup1" />
          <van-field is-link readonly label="学院" placeholder="请选择学院" :value="collegeText1" :rules="[{ required: true }]" @click="onPopup2" />
          <van-field is-link readonly label="专业" placeholder="请选择专业" :value="majorText1" :rules="[{ required: true }]" @click="onPopup3" />
        </van-cell-group>
      </van-tab>
      <van-tab title="按照初试科目">
        <van-cell-group>
          <van-field is-link readonly label="学校" placeholder="请选择学校" :value="universityText2" :rules="[{ required: true }]" @click="onPopup4" />
          <van-field is-link readonly label="考试科目" placeholder="请选择考试科目" :value="examSubjectText2" :rules="[{ required: true }]" @click="onPopup5" />
        </van-cell-group>
      </van-tab>
      <van-tab title="按照复试科目">
        <van-cell-group>
          <van-field is-link readonly label="学校" placeholder="请选择学校" :value="universityText3" :rules="[{ required: true }]" @click="onPopup6" />
          <van-field is-link readonly label="考试科目" placeholder="请选择考试科目" :value="examSubjectText3" :rules="[{ required: true }]" @click="onPopup7" />
        </van-cell-group>
      </van-tab>
    </van-tabs>

    <van-popup v-model="popup1" round position="bottom" class="popup">
      <van-cell-group :border="false" class="popup-content" title="请选择学校">
        <van-search v-model="search1" placeholder="请输入搜索关键词" @search="onSearch1" />
        <radio-item-group v-model="universityItem1" @change="onSelectChange1(true)">
          <radio-item v-for="item in searchList1" :label="item.UniversityName" :name="item" icon></radio-item>
        </radio-item-group>
      </van-cell-group>
    </van-popup>
    <van-popup v-model="popup2" round position="bottom" class="popup">
      <van-cell-group :border="false" class="popup-content" title="请选择学院">
        <van-search v-model="search2" placeholder="请输入搜索关键词" @search="onSearch2" />
        <radio-item-group v-model="collegeItem1" @change="onSelectChange2(true)">
          <radio-item v-for="item in searchList2" :label="item.CollegeCode + item.CollegeName" :name="item" icon></radio-item>
        </radio-item-group>
      </van-cell-group>
    </van-popup>
    <van-popup v-model="popup3" round position="bottom" class="popup">
      <van-cell-group :border="false" class="popup-content" title="请选择专业">
        <van-search v-model="search3" placeholder="请输入搜索关键词" @search="onSearch3" />
        <radio-item-group v-model="majorItem1" @change="onSelectChange3(true)">
          <radio-item v-for="item in searchList3" :label="`${item.MajorCode}${item.MajorName}/${item.CollegeCode}${item.CollegeName}`" :name="item" icon></radio-item>
        </radio-item-group>
      </van-cell-group>
    </van-popup>
    <van-popup v-model="popup4" round position="bottom" class="popup">
      <van-cell-group :border="false" class="popup-content" title="请选择学校">
        <van-search v-model="search4" placeholder="请输入搜索关键词" @search="onSearch4" />
        <radio-item-group v-model="universityItem2" @change="onSelectChange4(true)">
          <radio-item v-for="item in searchList4" :label="item.UniversityName" :name="item" icon></radio-item>
        </radio-item-group>
      </van-cell-group>
    </van-popup>
    <van-popup v-model="popup5" round position="bottom" class="popup">
      <van-cell-group :border="false" class="popup-content" title="请选择考试科目">
        <van-search v-model="search5" placeholder="请输入搜索关键词" @search="onSearch5" />
        <radio-item-group v-model="examSubjectItem2" @change="onSelectChange5(true)">
          <radio-item v-for="item in searchList5" :label="item.ExamSubjectCode + item.ExamSubjectName" :name="item" icon></radio-item>
        </radio-item-group>
      </van-cell-group>
    </van-popup>
    <van-popup v-model="popup6" round position="bottom" class="popup">
      <van-cell-group :border="false" class="popup-content" title="请选择学校">
        <van-search v-model="search6" placeholder="请输入搜索关键词" @search="onSearch6" />
        <radio-item-group v-model="universityItem3" @change="onSelectChange6(true)">
          <radio-item v-for="item in searchList6" :label="item.UniversityName" :name="item" icon></radio-item>
        </radio-item-group>
      </van-cell-group>
    </van-popup>
    <van-popup v-model="popup7" round position="bottom" class="popup">
      <van-cell-group :border="false" class="popup-content" title="请选择考试科目">
        <van-search v-model="search7" placeholder="请输入搜索关键词" @search="onSearch7" />
        <radio-item-group v-model="examSubjectItem3" @change="onSelectChange7(true)">
          <radio-item v-for="item in searchList7" :label="`${item.SecondSubjectName}/${item.MajorCode}${item.MajorName}`" :name="item" icon></radio-item>
        </radio-item-group>
      </van-cell-group>
    </van-popup>
  </div>
</template>

<script>
import RadioItem from "@/components/MT/RadioItem";
import RadioItemGroup from "@/components/MT/RadioItemGroup";
import Config from "@/api/service";
export default {
  name: "DocumentFilter",
  components: {
    RadioItem,
    RadioItemGroup,
  },
  props: {
    filterActive: {
      type: Number,
      default: 0,
    },
    filterData1: {
      type: Object,
      default: {
        universityCode: '',
        collegeCode: '',
        majorCode: '',
        examSubjectList: [],
      },
    },
    filterData2: {
      type: Object,
      default: {
        universityCode: '',
        examSubjectCode: '',
        examSubjectName: '',
      },
    },
    filterData3: {
      type: Object,
      default: {
        universityCode: '',
        majorCode: '',
        examSubjectName: '',
        firstSubjectList: [],
      },
    },
  },
  data() {
    return {
      popup1: false,
      popup2: false,
      popup3: false,
      popup4: false,
      popup5: false,
      popup6: false,
      popup7: false,
      search1: null,
      search2: null,
      search3: null,
      search4: null,
      search5: null,
      search6: null,
      search7: null,
      searchList1: [],
      searchList2: [],
      searchList3: [],
      searchList4: [],
      searchList5: [],
      searchList6: [],
      searchList7: [],
      universityInfos: [],
      collegeInfos: [],
      majorInfos: [],
      firstSubjectInfos: [],
      secondSubjectInfos: [],
    };
  },
  computed: {
    universityItem1: {
      get: function () {
        return this.universityInfos.find(item => item.UniversityCode == this.filterData1.universityCode)
      },
      set: function (item) {
        this.filterData1.universityCode = item.UniversityCode;
      }
    },
    collegeItem1: {
      get: function () {
        return this.collegeInfos.find(item => item.UniversityCode == this.filterData1.universityCode && item.CollegeCode == this.filterData1.collegeCode)
      },
      set: function (item) {
        this.filterData1.collegeCode = item.CollegeCode;
      }
    },
    majorItem1: {
      get: function () {
        return this.majorInfos.find(item => item.UniversityCode == this.filterData1.universityCode && item.CollegeCode == this.filterData1.collegeCode && item.MajorCode == this.filterData1.majorCode)
      },
      set: function (item) {
        this.filterData1.collegeCode = item.CollegeCode;
        this.filterData1.majorCode = item.MajorCode;
        this.filterData1.examSubjectList = item.ExamSubjectList;
      }
    },
    universityItem2: {
      get: function () {
        return this.universityInfos.find(item => item.UniversityCode == this.filterData2.universityCode)
      },
      set: function (item) {
        this.filterData2.universityCode = item.UniversityCode;
      }
    },
    examSubjectItem2: {
      get: function () {
        return this.firstSubjectInfos.find(item => item.ExamSubjectCode == this.filterData2.examSubjectCode)
      },
      set: function (item) {
        this.filterData2.examSubjectCode = item.ExamSubjectCode;
        this.filterData2.examSubjectName = item.ExamSubjectName;
      }
    },
    universityItem3: {
      get: function () {
        return this.universityInfos.find(item => item.UniversityCode == this.filterData3.universityCode)
      },
      set: function (item) {
        this.filterData3.universityCode = item.UniversityCode;
      }
    },
    examSubjectItem3: {
      get: function () {
        return this.secondSubjectInfos.find(item => item.MajorCode == this.filterData3.majorCode && item.SecondSubjectName == this.filterData3.secondSubjectName)
      },
      set: function (item) {
        this.filterData3.majorCode = item.MajorCode;
        this.filterData3.secondSubjectName = item.SecondSubjectName;
        this.filterData3.firstSubjectList = item.FirstSubjectInfos;
      }
    },
    activeValue: {
      get: function () {
        return this.filterActive;
      },
      set: function (val) {
        this.$emit('update:filterActive', val);
      }
    },
    universityText1() {
      if (this.universityItem1) {
        return this.universityItem1.UniversityName;
      } else {
        return this.filterData1.universityCode;
      }
    },
    collegeText1() {
      if (this.collegeItem1) {
        return this.collegeItem1.CollegeCode + this.collegeItem1.CollegeName;
      } else {
        return this.filterData1.collegeCode;
      }
    },
    majorText1() {
      if (this.majorItem1) {
        return this.majorItem1.MajorCode + this.majorItem1.MajorName;
      } else {
        return this.filterData1.majorCode;
      }
    },
    universityText2() {
      if (this.universityItem2) {
        return this.universityItem2.UniversityName;
      } else {
        return this.filterData2.universityCode;
      }
    },
    examSubjectText2() {
      if (this.examSubjectItem2) {
        return this.examSubjectItem2.ExamSubjectName;
      } else {
        return this.filterData2.examSubjectName;
      }
    },
    universityText3() {
      if (this.universityItem3) {
        return this.universityItem3.UniversityName;
      } else {
        return this.filterData3.universityCode;
      }
    },
    examSubjectText3() {
      if (this.examSubjectItem3) {
        return this.examSubjectItem3.ExamSubjectName;
      } else {
        return this.filterData3.majorCode;
      }
    },
  },
  methods: {
    //获取所有大学列表
    getAllUniversityList() {
      setTimeout(() => {
        var model = {};
        this.$axios
          .post(Config.document.filter.schoolList, model)
          .then((res) => {
            this.searchList1 = this.searchList4 = this.searchList6 = this.universityInfos = res.data.Data.UniversityInfos;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    //获取所有学院列表
    getAllCollegeList() {
      setTimeout(() => {
        var model = {
          UniversityCode: this.filterData1.universityCode
        };
        this.$axios
          .post(Config.document.filter.collegeList, model)
          .then((res) => {
            this.searchList2 = this.collegeInfos = res.data.Data.CollegeInfos;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    //获取所有专业列表
    getAllMajorList() {
      setTimeout(() => {
        var model = {
          UniversityCode: this.filterData1.universityCode,
          CollegeCode: this.filterData1.collegeCode
        };
        this.$axios
          .post(Config.document.filter.majorList, model)
          .then((res) => {
            this.searchList3 = this.majorInfos = res.data.Data.MajorInfos;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    //获取所有初试科目列表
    getAllExamSubjectList() {
      setTimeout(() => {
        var model = {
          UniversityCode: this.filterData2.universityCode
        };
        this.$axios
          .post(Config.document.filter.firstSubjectList, model)
          .then((res) => {
            //列表
            this.searchList5 = this.firstSubjectInfos = res.data.Data.FirstSubjectInfos;
            //设置Key
            this.firstSubjectInfos.forEach(item => {
              item.Key = item.ExamSubjectCode + item.ExamSubjectName;
            });
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    //获取所有复试科目列表
    getAllSecondSubjectList() {
      setTimeout(() => {
        var model = {
          UniversityCode: this.filterData3.universityCode
        };
        this.$axios
          .post(Config.document.filter.secondSubjectList, model)
          .then((res) => {
            //列表
            this.searchList7 = this.secondSubjectInfos = res.data.Data.SecondSubjectInfos;
            //设置Key
            this.secondSubjectInfos.forEach(item => {
              item.Key = item.MajorCode + item.ExamSubjectName;
            });
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    //选择大学改变
    onSelectChange1(hide) {
      if (hide) {
        this.popup1 = false;
      }
      this.filterData1.collegeCode = null;
      this.filterData1.majorCode = null;
      this.filterData1.examSubjectList = [];
      this.collegeInfos = [];
      this.majorInfos = [];
      this.searchList2 = [];
      this.searchList3 = [];
      this.getAllCollegeList();
      this.getAllMajorList();
      this.$emit("filter");
    },
    //选择学院改变（专业筛选）
    onSelectChange2(hide) {
      if (hide) {
        this.popup2 = false;
      }
      this.filterData1.majorCode = null;
      this.filterData1.examSubjectList = [];
      this.majorInfos = [];
      this.searchList3 = [];
      this.getAllMajorList();
      this.$emit("filter");
    },
    //选择专业改变（专业筛选）
    onSelectChange3(hide) {
      if (hide) {
        this.popup3 = false;
      }
      this.$emit("filter");
    },
    //选择大学改变（初试科目筛选）
    onSelectChange4(hide) {
      if (hide) {
        this.popup4 = false;
      }
      this.filterData2.examSubjectCode = null;
      this.filterData2.examSubjectName = null;
      this.firstSubjectInfos = [];
      this.searchList5 = [];
      this.getAllExamSubjectList();
      this.$emit('filter');
    },
    //选择考试科目改变（初试科目筛选）
    onSelectChange5(hide) {
      if (hide) {
        this.popup5 = false;
      }
      this.$emit('filter');
    },
    //选择大学改变（复试科目筛选）
    onSelectChange6(hide) {
      if (hide) {
        this.popup6 = false;
      }
      this.filterData3.majorCode = null;
      this.filterData3.secondSubjectName = null;
      this.filterData3.firstSubjectList = [];
      this.secondSubjectInfos = [];
      this.searchList7 = [];
      this.getAllSecondSubjectList();
      this.$emit('filter');
    },
    //选择考试科目改变（复试科目筛选）
    onSelectChange7(hide) {
      if (hide) {
        this.popup7 = false;
      }
      this.$emit('filter');
    },
    onPopup1() {
      this.popup1 = true;
    },
    onPopup2() {
      this.popup2 = true;
    },
    onPopup3() {
      this.popup3 = true;
    },
    onPopup4() {
      this.popup4 = true;
    },
    onPopup5() {
      this.popup5 = true;
    },
    onPopup6() {
      this.popup6 = true;
    },
    onPopup7() {
      this.popup7 = true;
    },
    onSearch1() {
      this.searchList1 = this.universityInfos.filter(p => p.UniversityCode.includes(this.search1) || p.UniversityName.includes(this.search1));
      if (this.searchList1.length > 0) {
        this.universityItem1 = this.searchList1[0];
        this.onSelectChange1(false);
      }
    },
    onSearch2() {
      this.searchList2 = this.collegeInfos.filter(p => p.CollegeCode.includes(this.search2) || p.CollegeName.includes(this.search2));
      if (this.searchList2.length > 0) {
        this.collegeItem1 = this.searchList2[0];
        this.onSelectChange2(false);
      }
    },
    onSearch3() {
      this.searchList3 = this.majorInfos.filter(p => p.MajorCode.includes(this.search3) || p.MajorName.includes(this.search3));
      if (this.searchList3.length > 0) {
        this.majorItem1 = this.searchList3[0];
        this.onSelectChange3(false);
      }
    },
    onSearch4() {
      this.searchList4 = this.universityInfos.filter(p => p.UniversityCode.includes(this.search4) || p.UniversityName.includes(this.search4));
      if (this.searchList4.length > 0) {
        this.universityItem2 = this.searchList4[0];
        this.onSelectChange4(false);
      }
    },
    onSearch5() {
      this.searchList5 = this.firstSubjectInfos.filter(p => p.ExamSubjectCode.includes(this.search5) || p.ExamSubjectName.includes(this.search5));
      if (this.searchList5.length > 0) {
        this.examSubjectItem2 = this.searchList5[0];
        this.onSelectChange5(false);
      }
    },
    onSearch6() {
      this.searchList6 = this.universityInfos.filter(p => p.UniversityCode.includes(this.search6) || p.UniversityName.includes(this.search6));
      if (this.searchList6.length > 0) {
        this.universityItem3 = this.searchList6[0];
        this.onSelectChange6(false);
      }
    },
    onSearch7() {
      this.searchList7 = this.secondSubjectInfos.filter(p => p.MajorCode.includes(this.search7) || p.MajorName.includes(this.search7) || p.ExamSubjectName.includes(this.search7));
      if (this.searchList7.length > 0) {
        this.examSubjectItem3 = this.searchList7[0];
        this.onSelectChange7(false);
      }
    },
  },
  mounted() {
    this.getAllUniversityList();
  }
};
</script>

<style scoped lang="less">
.popup {
  height: 80%;
}

.popup-content {
  margin: 0px 10px 10px 10px;
}
</style>
