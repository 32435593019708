<template>
    <div class="container-box">
        <div class="box-left">
            <div class="guide-box">
                <span class="numBox">
                    <span class="num1">1</span>
                    <span class="num2">/</span>
                    <span class="num2">2</span>
                </span>
                <span class="txt">请输入查询信息</span>
            </div>
            <div>
                <document-filter :filterActive.sync="filterActive" :filterData1.sync="filterData1" :filterData2.sync="filterData2" :filterData3.sync="filterData3" @filter="onFilterClick"></document-filter>
            </div>
            <div v-if="majorInfo && filterActive == 0" class="content-major">
                <div class="item">
                    <span class="title">研究方向</span>
                    <span class="text">{{ majorInfo.MajorResearchDirection }}</span>
                </div>
                <div class="item">
                    <span class="title">初试科目</span>
                    <span class="text">{{ majorInfo.MajorFirstSubject }}</span>
                </div>
                <div class="item">
                    <span class="title">复试科目</span>
                    <span class="text">{{ majorInfo.MajorSecondSubject }}</span>
                </div>
            </div>
            <div class="guide-box">
                <span class="numBox">
                    <span class="num1">2</span>
                    <span class="num2">/</span>
                    <span class="num2">2</span>
                </span>
                <span class="txt">查询结果（点击可预览）</span>
            </div>
            <div>
                <el-tabs class="school-tabs" type="card" v-model="typeActive" @tab-click="onTabChange">
                    <el-tab-pane name=" " :label="`全部（${countAll}）`"></el-tab-pane>
                    <el-tab-pane name="2" :label="`真题（${countExamSubject}）`"></el-tab-pane>
                    <el-tab-pane name="0" :label="`初试（${countFirstSubject}）`"></el-tab-pane>
                    <el-tab-pane name="1" :label="`复试（${countSecondSubject}）`" v-if="filterActive != 1"></el-tab-pane>
                </el-tabs>
                <div class="school-list" v-loading="loading" element-loading-text="加载中...">
                    <template v-for="documentInfo in documentInfos">
                        <document-item :documentName="documentInfo.DocumentName" :documentPrice="documentInfo.DocumentPrice" @item-click="onItemClick(documentInfo)"></document-item>
                    </template>
                </div>
            </div>
            <div class="pagination-box">
                <el-pagination background hide-on-single-page :current-page.sync="pageIndex" :page-size.sync="pageSize" :total.sync="totalCount" layout="total, prev, pager, next, jumper" @current-change="onCurrentChange"></el-pagination>
            </div>
        </div>
        <div class="box-right">
            <school-hot></school-hot>
        </div>
    </div>
</template>

<script>
import DocumentFilter from "@/components/PC/DocumentFilter";
import DocumentItem from "@/components/PC/DocumentItem";
import SchoolHot from "@/components/PC/SchoolHot";
import Config from "@/api/service";

export default {
    name: "PCModule",
    components: {
        DocumentFilter,
        DocumentItem,
        SchoolHot,
    },
    data() {
        return {
            loading: true,
            pageIndex: 1,
            pageSize: 30,
            totalCount: 0,
            countAll: 0,
            countFirstSubject: 0,
            countSecondSubject: 0,
            countExamSubject: 0,
            keyword: '',
            typeActive: ' ',
            filterActive: 0,
            filterData1: {
                universityCode: '',
                collegeCode: '',
                majorCode: '',
                examSubjectList: [],
            },
            filterData2: {
                universityCode: '',
                examSubjectCode: '',
                examSubjectName: '',
            },
            filterData3: {
                universityCode: '',
                majorCode: '',
                examSubjectName: '',
            },
            majorInfo: null,
            documentInfos: [],
        };
    },
    methods: {
        //获取路由参数
        getRoutParams() {
            this.keyword = this.$route.query.keyword;
        },
        //获取专业信息
        getMajorInfo() {
            if (this.filterActive == 0 && this.filterData1.universityCode && this.filterData1.collegeCode && this.filterData1.majorCode) {
                var model = {
                    UniversityCode: this.filterData1.universityCode,
                    CollegeCode: this.filterData1.collegeCode,
                    MajorCode: this.filterData1.majorCode,
                };
                this.$axios
                    .post(Config.document.major.info, model)
                    .then((res) => {
                        this.majorInfo = res.data.Data;
                    })
                    .catch((error) => {
                        this.$message.error(error.message);
                    });
            }
        },
        //获取文档数量
        getDocumentCount() {
            if (this.filterActive == 0) {
                var model = {
                    SearchType: 0,
                    SearchKeyword: this.keyword,
                    UniversityCode: this.filterData1.universityCode,
                    MajorCode: this.filterData1.majorCode,
                    ExamSubjectList: this.filterData1.examSubjectList,
                };
            } else if (this.filterActive == 1) {
                var model = {
                    SearchType: 1,
                    SearchKeyword: this.keyword,
                    UniversityCode: this.filterData2.universityCode,
                    ExamSubjectCode: this.filterData2.examSubjectCode,
                    ExamSubjectName: this.filterData2.examSubjectName,
                };
            } else if (this.filterActive == 2) {
                var model = {
                    SearchType: 0,
                    SearchKeyword: this.keyword,
                    UniversityCode: this.filterData3.universityCode,
                    MajorCode: this.filterData3.majorCode,
                    ExamSubjectList: this.filterData3.firstSubjectList,
                };
            }
            this.$axios
                .post(Config.document.count, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.countAll = data.CountAll;
                    this.countFirstSubject = data.CountFirstSubject;
                    this.countSecondSubject = data.CountSecondSubject;
                    this.countExamSubject = data.CountExamSubject;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //获取文档列表
        getDocumentInfos() {
            this.loading = true;
            if (this.filterActive == 0) {
                var model = {
                    SearchType: 0,
                    SearchKeyword: this.keyword,
                    DocumentType: this.typeActive,
                    UniversityCode: this.filterData1.universityCode,
                    MajorCode: this.filterData1.majorCode,
                    ExamSubjectList: this.filterData1.examSubjectList,
                    PageIndex: this.pageIndex,
                    PageSize: this.pageSize,
                };
            } else if (this.filterActive == 1) {
                var model = {
                    SearchType: 1,
                    SearchKeyword: this.keyword,
                    DocumentType: this.typeActive,
                    UniversityCode: this.filterData2.universityCode,
                    ExamSubjectCode: this.filterData2.examSubjectCode,
                    ExamSubjectName: this.filterData2.examSubjectName,
                    PageIndex: this.pageIndex,
                    PageSize: this.pageSize,
                };
            } else if (this.filterActive == 2) {
                var model = {
                    SearchType: 0,
                    SearchKeyword: this.keyword,
                    DocumentType: this.typeActive,
                    UniversityCode: this.filterData3.universityCode,
                    MajorCode: this.filterData3.majorCode,
                    ExamSubjectList: this.filterData3.firstSubjectList,
                    PageIndex: this.pageIndex,
                    PageSize: this.pageSize,
                };
            }
            this.$axios
                .post(Config.document.list, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.totalCount = data.TotalCount;
                    this.documentInfos = data.DocumentInfos;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                }).finally(() => {
                    this.loading = false;
                });
        },
        //点击资料项
        onItemClick(documentInfo) {
            this.$router.push({
                name: 'Document1',
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    documentGuid: documentInfo.DocumentGuid
                }
            })
        },
        //点击过滤
        onFilterClick() {
            this.keyword = '';
            this.getDocumentCount();
            this.getDocumentInfos();
            this.getMajorInfo();
        },
        //选项卡切换
        onTabChange() {
            this.pageIndex = 1;
            this.getDocumentInfos();
        },
        //点击分页
        onCurrentChange() {
            this.getDocumentInfos();
        }
    },
    mounted() {
        this.getRoutParams();
        this.getDocumentCount();
        this.getDocumentInfos();
        this.getMajorInfo();
    }
};
</script>
<style scoped lang="less">
.container-box {
    display: flex;
    flex-direction: row;
}

.box-left {
    flex: 1;
}

.box-right {
    width: 300px;
    margin: 0 0 0 50px;
}

.school-tabs {
    margin-top: 10px;
}

.school-list {
    min-height: 100px;
    padding: 20px;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}

.content-major {
    border: 1px solid #e6e6e6;
    padding: 10px 30px;
    margin: 10px 0;

    .item {
        display: flex;
        margin: 20px 0;

        .title {
            color: #555;
            font-size: 14px;
            font-weight: 700;
        }

        .text {
            color: #555;
            font-size: 14px;
            margin-left: 20px;
            white-space: pre-wrap;
        }
    }
}

.bottom-line {
    border-top: 1px solid #e6e6e6;
    margin: 10px 0;
}

.pagination-box {
    text-align: center;
    margin: 10px;
}

.guide-box {
    margin: 20px 0;
    font-size: 18px;

    .numBox {
        display: inline;
        letter-spacing: 5px;

        .num1 {
            color: #ff6602;
            font-size: 20px;
            font-weight: 700;
        }

        .num2 {
            color: gray;
            font-size: 16px;
        }
    }

    .txt {
        margin-left: 10px;
    }
}

::v-deep .el-tabs__header {
    margin: 0px;
}
</style>